import $ from "jquery";

// ハンバーガーメニュー
const active = '-active';
$('#js-hamburger').on('click', (event) => {
  $(event.currentTarget).toggleClass(active);
  $('#js-hamburgerInner').toggleClass(active);
  $('.js-hamburgerLine').toggleClass(active);
  $('#js-gnav').toggleClass(active);
  $('#js-wrap').toggleClass('-disable');
  return false;
});

$('#js-gnav a').on('click', (event) => {
  $('#js-hamburger').removeClass(active);
  $('#js-hamburgerInner').removeClass(active);
  $('.js-hamburgerLine').removeClass(active);
  $('#js-gnav').removeClass(active);
  $('#js-wrap').removeClass('-disable');
});
