import $ from "jquery";
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

// 共通設定
const recommendSwiperWrap = '#js-recommendSwiper',
      recommendSwiper = recommendSwiperWrap + ' .swiper-container',
      param = {
        params: {
          breakpoints: {
            768: {
              spaceBetween: 20,
            },
          },
          navigation: {
            nextEl: recommendSwiperWrap + ' .swiper-button-next',
            prevEl: recommendSwiperWrap + ' .swiper-button-prev',
          },
          slidesPerView: 2,
          spaceBetween: 10
        },
      };

// 共通設定の読み込み
Swiper.use(param);

// スライドの数を数える
let cnt = $(recommendSwiper + ' .swiper-slide').length;

if(cnt == 2) {
  $('#js-recommendContainer').addClass('-two');
}

// スライドの数によってswiperの設定を変える
// インタビューは4
let swiper;
if($(recommendSwiperWrap).hasClass('-interview')) {
  swiper = new Swiper(recommendSwiper, {
    breakpoints: {
      768: {
        slidesPerView: 4,
      },
    },
  });
} else {
  if(cnt < 3) {
    swiper = new Swiper(recommendSwiper, {
      slidesPerView: cnt,
      watchOverflow: true,
    });
  } else if(cnt == 3) {
    swiper = new Swiper(recommendSwiper, {
      breakpoints: {
        768: {
          slidesPerView: cnt,
          watchOverflow: true
        },
      },
    });
  } else {
    swiper = new Swiper(recommendSwiper, {
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
      },
    });
  }
}

